
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.search {
  $root: &;

  &__item {
    padding: 24px;
    background: $color-white;
    box-shadow: $base-shadow;
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &--favorites {
      #{$root}__item-actions {
        flex-shrink: 0;

        .esmp-button {
          color: $color-service-manager-logo;
        }
      }
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__description {
    color: $color-black-op-50;
    margin-bottom: 16px;
  }

  &__item-content {
    margin-right: 20px;
    max-width: 584px;
  }

  &__item-actions {
    flex-shrink: 0;

    .esmp-button {
      color: $color-black;
    }
  }

  &__ticket-toolbar {
    display: flex;
    justify-content: flex-end;

    @include for-size(phone-landscape-down) {
      margin-bottom: 6px;
    }
  }

  &__filter {
    color: $color-black;
    @include hover(true) {
      color: $primary-color-hover;
    }
  }

  &__ticket-head {
    display: flex;
    padding: 0 20px 8px;
    align-items: center;

    @include for-size(phone-landscape-down) {
      align-items: unset;
    }
  }

  &__ticket-col-name {
    font-size: 14px;
    line-height: 20px;
    color: $color-black-op-50;
    &:first-child {
      width: 25%;
    }
    &:nth-child(2) {
      width: 25%;
    }
    &:nth-child(3) {
      width: 10%;
      text-align: center;
    }
    &:nth-child(4) {
      width: 22%;
      text-align: center;
    }
    &:nth-child(5) {
      width: 18%;
      text-align: center;
    }

    @include for-size(phone-landscape-down) {
      font-size: 12px;
      line-height: 16px;
    }

    @include for-size(phone-portrait-down) {
      display: none;
    }
  }

  &__pagination {
    padding-top: $base-gutter;
    display: flex;
    justify-content: center;
  }
}
